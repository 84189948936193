.about{
  width: 100%;
  position:relative;
  margin: 10px;
  font-size: 16px;
  color: #000;
  float: left;
  img{
    height: 300px;
    clear: none;
    float: left;
    margin: 0 10px 6px 0;
  }
  >div{
  	    user-select: none;
    width: 80%;
    margin: 40px auto;
    padding: 10px;
        text-align: justify;
      >h3{
	  	    font-size: 24px;
	    text-align: center;
	    color: #435d96;
 		 }
 		 .importantText{
 		 	font-weight:bold;
 		 	text-align:center;
 		 }
  }

}


#aboutbavkground{
	    clear: none;
    float: left;
    margin: 0 10px 6px 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.2;
}

