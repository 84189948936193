#clider-container{
  width:100%;
  height: calc(100% - 110px);
  overflow: hidden;
  position: relative;

  .slide{
    width: 100%;
    height: 100%;
    position: absolute;
    left: -100%;
    font-size: 30px;
    overflow: hidden;

    img.background{
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -1000;
      overflow: hidden;
      max-width: 100%;

    }
    &:after{
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      right: 0;
      bottom: 0;
      background: rgba(42, 54, 39, 0.7);
    }
    .slide_info{
      position: absolute;
      top: 50%;
      right: calc((100% - 960px) / 2);
      width: 300px;
      height: 250px;
      margin-top: -125px;
      z-index: 2;

      .top-text{
        font-size: 45px;
        padding: 0 0 8px 0;
        line-height: 1;
        border-bottom: 1px solid $basic-green;
        text-align: right;
        color: #fff;
      }
      .prise{
        font-size: 23px;
        padding: 10px 0 0 0;
        color: #fff;
        text-align: center;
      }
      .button{
        display: inline-block;
        line-height: 36px;
        font-size: 14px;
        font-weight: normal;
        text-decoration: none;
        margin-top: 11px;
        padding: 3px 53px 0 19px;
        position: relative;
        color: #fff;
        background: $basic-green;
        position: relative;
        float: right;
        &:hover,&:hover &:after{
          background: lighten($basic-green,5%);
        }


        &:after{
          content: '>';
          position: absolute;
          width: 36px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          right: 0;
          border-left: 1px solid #fff;
          top: 2px;

          &:hover{
            background: lighten($basic-green,5%);
          }
        }
      }
    }

    &:first-child{
      left: 0;
    }

  }
  #pagination{
    position: absolute;
    z-index: 100;
    width: 120px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 30px;
    left: 50%;
    margin-left: -60px;

    div{
      width: 15px;
      height: 15px;

      cursor: pointer;
      box-sizing: border-box;
      border: 3px solid #70d4a0;
      background: transparent;
      &:hover,&.cheked{
        background: $basic-green;
      }
      //&:first-child{
      //  background: $basic-green;
      //}

    }


  }
}