.bin-options{
    width: 100%;
    height: 30px;
    margin-top: 30px;
    background: #ec4848;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

   >div{
      height: 100%;
      line-height: 30px;
      text-align: left;
      padding-left: 20px;
      color: #fff;
      font-size: 16px;
      box-sizing: border-box;
   }
}

.foto{
  width: 200px;
}
.name{
  width: 200px;
}
.options{
  width: 200px;
}
.price{
  width: 150px;
}

.amount{
  width: 130px;
}



#clear_bin{
  width: 195px;
  height: 40px;
  border: 1px solid $red;
  color: $red;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  float: right;
  margin: 10px 0;
  box-sizing: border-box;
  cursor: pointer;

  &:hover{
    background: $red;
    border-color: transparent;
    color: #fff;


  }
}






.bin-tovar{
  display: flex;
  margin: 10px 0;
  border: 1px solid $basic-text-gray;
  max-height: 130px;
  >div{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

  }
  .delete{
    width: 80px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    >i{
      font-size: 25px;
      color: $red;
      cursor: pointer;
    }
  }

  .foto{
    img{
      width: 100%;
      height: 100%;
    }
  }

  .name {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    >div , a{
      margin-top:5px;
    }
    a.tovar-name {
      font-size: 17px;
      color: #000;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: bold;
      &:hover{
        text-decoration: underline;
      }
    }
    .star_rating{
      width: 70px;
      height: 13px;
      display: flex;
      justify-content: space-between;

      i{
        font-size: 13px;
        color:$basic-text-gray;
      }
    }
    .artukul{
      font-size: 10px;
      color:#000;
      span{
        color:$basic-text-gray;
      }
    }
    a.bin{
      color: $basic-green;
      font-size: 27px;
    }
  }

  .options{
    display: flex;
    flex-direction: column;
    justify-content: center;
    .preizvoditel{
      border: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 50px;
      justify-content: center;
      line-height: 20px;


      a{
        color: #000000;
      }
    }
    >div{
      font-size: 14px;
      width: 90%;
      min-height: 30px;
      border-top: 1px solid $basic-gray;
      color: $basic-text-gray;
      display: flex;
      align-items: center;
      >div{
        display: flex;
        align-items: center;
        width: 50%;
        height: 100%;
        &:last-child{
          color: #000;
          width:50%;
          display: block;
        }
      }
    }
  }
  .price{
    display: flex;
    justify-content: center;
    padding-left: 10px;
    flex-direction: column;
    .price-old{
      span{
        font-weight: bold;
        font-size: 15px;
        color: #0e0e0e;
        text-decoration: line-through;
      }

    }
    .price-current{
      color:$basic-green;
      font-size: 25px;
    }
  }






}



.sumUpprise{
width: 100%;
    font-size: 25px;
    text-align: center;
    height: 30px;
    line-height: 40px;
    color: #fff;
    background: #ec4848;
    margin: 20px 0;
    float: left;
    line-height: 30px;
}



.buyGrup {
  width: 100%;
  display: flex;
  align-items: center;
  position:relative;
}
  .dostavka {
    width: 360px;
       height: 275px;
    overflow: hidden;
    background: $basic-gray;
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .h1 {
      font-size: 25px;
      text-align: center;
    }
    p{
    	    text-align: justify;
    }
  }

  .buyStaticForm {
    font-size: 20px;
        height: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
        background-image: url(/img/oneclick.png);
    flex-wrap: wrap;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    align-content: center;
    .error{
    	    top: -30px;
    }

    > .inputGrup > div {
      width: 286px;
      height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      &.important_input{
        &::after{
    position: absolute;
    content: '*';
    left: 44px;
    top: 4px;
    font-size: 14px;
          color: $red;
        }
      }

      > input {

        width: 200px;
        height: 25px;
      padding-left: 5px;

      }
    }

    > div, .textarea {
      height: 155px;
      width: 286px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      textarea{
      	height:100%;
      }
    }

    .zakazat{
      width: 200px;
      text-align: center;
      height: 35px;
      line-height: 35px;
      background: $red;
      color: #fff;
      cursor: pointer;
      border-radius: 5px;
      box-sizing: border-box;
          margin-top: 40px;
      &:hover{
        background:lighten($red,5%);
      }
    }
  }


  #empty_bin{
    width: 100%;
    font-size: 16px;
    padding: 10px;
    color: #000;
    margin-top: 20px;
    text-align: center;
    color: #435d96;
    font-size: 2.5rem;

    &.hidden{
      display: none;
    }
  }



  .amount{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
    .amount-grup{
      position: relative;
      width: 130px;
      height: 40px;
      display: flex;
      justify-content: space-between;

      >div{
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $basic-gray;
        color: #000;
        font-size: 15px;
        cursor: pointer;
      }

      .minus{
        background: $basic-green;
        color: #fff;
      }
      .plus{
        background: $blue;
        color: #fff;
      }
      .amount-grup-amount{
        cursor: default;
      }
      .showMaxVal{
        position: absolute;
        bottom: 50px;
        right: 0;
        z-index: 2;
        width: 130px;
        height: 25px;
        background: $red;
        color: #fff;
        font-size: 12px;
        display: none;
        text-align: center;
        line-height: 25px;
        >div{
          position: absolute;
          border: 11px solid transparent;
          border-top-color: $red;
          bottom: -18px;
          right: 10px;
        }
      }
    }
  .urInfo{
    position: absolute;
    z-index: 11;
    font-size: 14px;
    /* top: 2px; */
   bottom: 65px;

    right: 134px;
    .chekbox_wapper{
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      float: left;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: black;
      border-radius: 5px;
      margin-right: 4px;
      width: 18px;
      height: 18px;
    }
    input{
    	float:left;
      //margin:0;
          width: 15px;
    border-color: red;
    height: 15px;
    cursor: pointer;
    }
    .UrError{
    	    left: -150px  !important;
    	        top: -37px !important;
    	>div{
    		    left: 149px !important;
    	}
    }
  }


  #korzina_backgroundImg{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 9rem;
    color: #70d4a0;
    top: 0;
    position: absolute;
    left: 41%;
    opacity: 0.3;
    top: 20%;
    z-index: -1;
  }

