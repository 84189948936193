.news{
 // height: 400px;
  width: 100%;
  margin-top: 30px;
  border:1px solid darken($basic-gray,10%);
  border-radius: 5px;

  .news-h1{
    font-size: 20px;
    color: #000;
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .news-new{
    padding-left: 12px;
    box-sizing: border-box;
    margin-bottom: 15px;
    .news-new-data{
      width: 80px;
      height: 20px;
      background: $basic-gray;
      font-size: 13px;
      display: FLEX;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
    }

    .news-new-text{
      width: 90%;
      >a{
        font-size: 13px;
        text-decoration: none;
        color: #000;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  .archiv{
    font-size: 15px;
    width: 80px;
    height: 30px;
    background: $basic-green;
    display: block;
    display: block;
    text-align: center;
    line-height: 30px;
    text-decoration: none;
    color: #fff;
    margin: 17px auto 10px auto;
    border-radius: 4px;
    &:hover{
      background: lighten($basic-green,10%);
    }
  }
}




.novuna-prewiu,.novuna-item{
  width: 100%;
  margin:20px 0;
  border:1px solid darken($basic-gray,5%);
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px;
  display: block;
  text-decoration: none;
  text-align: justify;
  h2{
    font-size: 20px;
    color: #000;
    margin-bottom: 28px;
  }
  p{
    font-size: 15px;
    color: #000;
        text-align: justify;
  }
  img{
    float: left;
    height: 300px;
    padding: 0 14px 5px 0;
  }
}

.novuna-item{
  margin: 0;
  height: auto;
  border:none;
  font-size: 15px;
  color: #000;

  img{
  	float:left;
  	height:300px;
  }

}

#clock{
    font-size: 0;
    overflow: hidden;
    color: black;
    padding-bottom: 10px;
    text-align: center;
    background-color: white;
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 0;
    >ul.flip {
		position: relative;
		float: left;
		margin: 3px;
		width: 2px;
		height: 23px;
		font-size: 7px;
		font-weight: bold;
		line-height: 33px;
		padding: 10px;
		border-radius: 6px;
		background: #000;
		>li{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			line-height: 25px;
			text-decoration: none !important;
			>a{
			display: block;
			height: 100%;
			-webkit-perspective: 200px;
			-moz-perspective: 200px;
			perspective: 200px;
			margin: 0 !important;
			overflow: visible !important;
			cursor: default !important;
				
					.inn{
					    position: absolute;
					    left: 0;
					    z-index: 1;
					    width: 100%;
					    height: 200%;
					    color: #fffbfb;
					    text-align: center;
					    background-color: #70d4a0;
					    border-radius: 6px;
					    font-size: 20px;
					    line-height: 25px;
					}
				
			}
		}
    }
    .flip-clock-divider{
	    float: left;
	    display: inline-block;
	    position: relative;
	    width: 14px;
	    height: 56px;
	    >.flip-clock-dot{
			display: block;
			background: #323434;
			width: 4px;
			height: 4px;
			position: absolute;
			border-radius: 50%;
			left: 5px;
			&.top{
				    top: 9px;
			}
	    }
    }
}