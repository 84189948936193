aside {
  width: 220px;


  .menu-wrapper {
    background: $basic-gray;
    .category {
    width: 220px;
    height: 40px;
    background: #435d96;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-decoration: none;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border:2px solid $dark-blue;
    box-sizing:border-box;
      &:hover{
        background: #fff;
        color:$dark-blue;


        div{
          color:$dark-blue;
        }
        span{
          color:$dark-blue;
        }
      }

      div {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 38px;
        font-size: 22px;
        color: #fff;
      }
      span {
        font-size: 16px;
        color: #fff;
        height: 40px;
        display: block;
        padding-left: 21px;
        line-height: 36px;
        font-weight: bold;
      }
    }
    > ul {
      padding: 0;
      width: 100%;
      list-style-type: none;
      > li {
        border-top: 1px solid darken($basic-gray,15%);
        font-size: 15px;

        > a {
          width: 220px;
          min-height: 40px;
          font-size: 15px;
          display: block;
          padding-left: 25px;
          line-height: 40px;
          position: relative;
          box-sizing: border-box;
          font-size: 15px;
          text-decoration: none;
          color: #000;

          span{
            position: absolute;
            top: 0;
            right: 20px;
            font-size: 15px;
            color: #000;
          }


        }
        &.active,&:hover  {
        	        background: #d2d1d1;
        	        >a{
        	        	    font-weight: bold;
        	        }
        	    >ul{
        	    	>li.Pidkategory-active,>li:hover{
						background: #fff;
						color: black;
						box-sizing: border-box;
					  //	margin-right: 5px;
						//margin-left: 5px;
						border: 1px solid #c8c8c8;
            cursor: pointer;
        	    	}

   				 }
			}
    /*  &:hover {
                          >a{
                        font-weight: 100;
                  }
      }
      &.active:hover{
        >a{
          font-weight: bold !important;
        }

      }*/
        > ul {
          list-style-type: none;
          width: 100%;
          padding: 6px 0;
          border-top: 1px solid darken($basic-gray,15%);
          > li {
            height: 28px;
            //padding-left: 12px;
            line-height: 28px;
            >a{
              color: #000;
              text-decoration: none;
              font-size: 12px;
              padding-left: 12px;
              box-sizing: border-box;
              >i{
                    margin-right: 8px;
              }
            }

          }

        }
      }
    }
  }
}
