footer{
  width: 100%;
  background: $dark-blue;
  margin-top: 40px;
  float: left;

  &.fixed{
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .footer-wrap{
    width: 960px;
    height: 175px;
    margin: 0 auto;
    padding-top: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;



    .footer-contactu{
      width: 206px;

      .footer-contactu-h1{
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        text-align: center;
      }
      .footer-contactu-in{
        width: 100%;

        .footer-contactu-in-contact{
          width: 100%;
          height: 43px;
          display: flex;

          .footer-contactu-in-contact-icon{
            height: 100%;
            width: 35px;
            font-size: 25px;
            color:$basic-green;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .footer-contactu-in-contact-text{
            width: calc(100% - 35px);
            height: 100%;
            font-size: 16px;
            display: flex;
            color: #fff;
            flex-wrap: wrap;
            align-items: center;
            box-sizing: border-box;
            padding-left: 7px;
            >a{
              color: #fff;
              text-decoration: none;
              &:hover{
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .footer-podpiska{
      width: 500px;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .footer-podpiska-h1{
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        text-align: center;
      }
      .footer-podpiska-text{
        font-size: 14px;
        color: #fff;
        width: 58%;
      }
      .footer-podpiska-form{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        position: relative;

        input{
          border:none;
          background: darken($dark-blue,10%);
          width: 200px;
          outline: none;
          height: 100%;
          font-size: 17px;
          box-sizing: border-box;
          padding-left: 8px;
          color: #fff;
        }
        .footer-podpiska-form-button{
          height: 100%;
          width: 90px;
          display: flex;
          font-size: 15px;
          background: $basic-green;
          color: #fff;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        #email_error{
          top: -28px;
          left: 95px;
        }
      }
    }
    .footer-right-wrapper{
    	display:flex;
    	flex-direction:column;
    	align-items: center;
	        .footer-ur-info{
	        	 margin-top: 10px;
				color: #fff;
				width: 300px;
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 13px;
				>a{
					color: #fff;
				}
	    	}	
		    .footer-socialNetvorks{
	      width: 300px;
	      height: 100px;

	      .footer-socialNetvorks-h1{
	        font-size: 20px;
	        font-weight: bold;
	        color: #fff;
	        text-align: center;
	      }
	         .footer-socialNetvorks-icons{
	           width: 100%;
	           justify-content: space-between;
	           align-items: center;
	           display: flex;
	           margin-top: 10px;
	        a{

	          i{
	            font-size: 55px;
	            color: #5d75ad;

	            &:hover{
	              color: $basic-green;
	            }
	          }
	        }
	      }
	    }
    }




  }
  .footer-copirate{
    width: 960px;
    margin: 5px auto 0 auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .footer-copirate-text{
      font-size: 15px;
      color: #fff;
    }
  }



}

  #footer_emailOK{
  	    display: none;
    flex-direction: row;
    height: 50px;
    >div{
    	>i{
    		border: 4px solid #70d4a0;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    text-align: center;
    line-height: 27px;
    font-size: 18px;
    color: #fff;
    background: #70d4a0;
    	}
    }
  }