.tovars-recomanded-wrapper{
  width: 720px;

//kategory_pidkazki
  .pagination{
    padding-top: 20px;
  }
.recomend{
    width: 672px;
    margin-left: 19px;

  .recomend-header{
    width: 100%;
    height: 35px;
    background: rgba(112, 212, 160, 0.51);
    /* border-radius: 50px; */
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /* padding-left: 15px; */
    box-sizing: border-box;
    overflow: visible;

    span{
	display: flex;
    width: 112px;
    height: 100%;
    align-items: center;
    background: rgba(112, 212, 160, 0.57);
    padding-left: 17px;
    color: #fff;
    font-size: 17px;
      
    }

    .recomend-form{
        width: 527px;
    position: relative;
    display: flex;
    align-items: center;

    	       

    	    input{
            height: 20px;
            width: calc(100% - 58px);
            padding: 2px;
            padding-left: 10px;
                &:hover{
                  outline: -webkit-focus-ring-color auto 5px;
                  outline-color: -webkit-focus-ring-color;
                  outline-style: auto;
                  outline-width: 5px;
                }
    	    }
        input::-webkit-input-placeholder { color: $basic-text-gray }
        input:-moz-placeholder { color: $basic-text-gray; }
        input::-moz-placeholder { color: $basic-text-gray; }
        input:-ms-input-placeholder { color: $basic-text-gray; }
    	    button{
            height: 35px;
            width: 40px;
            background: transparent;
            border: none;
            color: #fff;
            font-size: 20px;
            /* display: block; */
            padding: 0;
            cursor: pointer;
    	    }
          div.kategory_pidkazki{
          position: absolute;
          background: #70d4a0;
          top: 40px;
          max-height: 300px;
          z-index: 11;
          overflow-y: scroll;
          width: calc(100% - 42px);
          display: none;
          a{
              color:black;
              text-decoration: none;
              display: block;
              width: 100%;
              padding-left: 10px;
              box-sizing: border-box;
              border-bottom: 1px solid #eeeeee;
                  font-size: 12px;
                      height: 25px;
    line-height: 25px;


              strong{
                margin-right: 7px;
                    font-size: 12px;
              }

              &:hover{
background: darken($basic-green,15%);

              }
                          &.notFullMatchLink{
              background: #b8c7bf;
              &:hover{
                background: darken($basic-green,10%);
              }
            }
          }

        }
    }

  }
}




  .tovar-recomanded{
      float: left;
      background: #eee;
      height: 200px;
      width: 200px;
      border: 1px solid #d5d4d4;
      border-radius: 5px;
      overflow: hidden;
      margin: 10px 19px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: distribute;
      justify-content: space-around;
    a.img{
      width: 87%;
      height: 121px;
      margin: 8px 0;
      display: flex;
      justify-content: center;
      overflow: hidden;
      align-items:center;
    img{  
      width:100%;
    }
  }

    a{
      font-size: 14px;
      color: $blue;
      text-align: center;
    }

    .tovars-recomanded-bin{
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      >div{
        height: 30px;
        border-radius: 4px;
        border:1px solid darken($basic-gray,10%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
      .tovars-recomanded-bin-amount{
        width: 90px;
           color: #191919;
    background: white;
      }
      .tovars-recomanded-bin-add{
        width: 80px;
        background: $blue;
        color: $basic-gray;
        cursor: pointer;
        &:hover{
          color: $blue;
          background: $basic-gray;
          border-color: $blue;
        }
        &.vKorzini{
          background: $red;
          border-color: $red;
         // border-radius: 0;
          &:hover{
            cursor: default;
            background: $red;
            color: #fff;
            border-color: $red;
          }
        }
      }
    }
  }
}
