ul.pagination{
	font-size: 20px;

	li{
	    display: block;
	    width: 35px;
	    height: 35px;
	    line-height: 35px;
	    text-align: center;
	    background: #70d4a0;
	    border-radius: 50%;
	    color: #fff !important;
	    margin: 0 5px;

    	a{
    		text-decoration: none;
    		color: #fff !important;
			width: 100%;
			height: 100%;
			display: block;
    	}

    	&.disabled{
    		cursor: not-allowed;
    	}

    	&.active{
    		background: $red;
    	}
	}
}
