//kategory_pidkazki
.wrapper {
  width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
      min-height: calc(100% - 250px);


  main{
    width: 720px;
    min-height:calc(100% - 310px);



    .sortirovka{
      width: 100%;
      height: 50px;
      background: $basic-gray;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .sortirovka_search{
          width: calc(100% - 300px);
        /* align-self: flex-end; */
        font-size: 25px;
        background: #fff;
        height: 35px;
        box-sizing: border-box;
        border: 1px solid lighten($basic-text-gray,20%);
        border-radius: 3px;
        position: relative;
        &:hover,&:active,&:focus,&.active{
          border: 1px solid #70d4a0;
        }
        input{
          height: 33px;
          padding-left: 16px;
          box-sizing: border-box;
          outline: 0;
          width: calc(100% - 41px);
          float: left;
          border: 0;
          margin: 0;




        }
        input::-webkit-input-placeholder { color: $basic-text-gray }
        input:-moz-placeholder { color: $basic-text-gray; }
        input::-moz-placeholder { color: $basic-text-gray; }
        input:-ms-input-placeholder { color: $basic-text-gray; }
        i{
          display: block;
          float: right;
          text-align: center;
          line-height: 34px;
          width: 40px;
          height: 33px;
          background: #70d4a0;
          color: #fff;
          cursor: pointer;
        }

        div.kategory_pidkazki{
            position: absolute;
            width: 100%;
            background: #70d4a0;
            top: 38px;
            z-index: 11;
            max-height: 225px;
            overflow-y: scroll;
          a{
              color:black;
              text-decoration: none;
              display: block;
              width: 100%;
              padding-left: 10px;
              box-sizing: border-box;
              border-bottom: 1px solid #eeeeee;
                  font-size: 12px;
                      height: 25px;
    line-height: 25px;


              strong{
                margin-right: 7px;
                    font-size: 12px;
              }

              &:hover{
background: darken($basic-green,15%);

              }
            &.notFullMatchLink{
              background: #b8c7bf;
              &:hover{
                background: darken($basic-green,10%);
              }
            }
          }

        }
      }
        .sortirovka-select{
          background: #fff;
          width: 125px;
          height: 30px;
          border: 1px solid #c8c8c8;
          border-radius: 4px;
          outline: none;
          color: $basic-text-gray;
          margin-left: 5px;
          position: relative;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          cursor: pointer;

          .sortirovka-select-options{
            width: 125px;
            height: 60px;
            display: none;
            position: absolute;
            top:32px;
            background: #c8c8c8;
            left: 0;
            z-index: 10;

            .sortirovka-select-options-option{
              background: #fff;
                 width: 125px;
              height: 30px;
              border: 1px solid #c8c8c8;
              border-radius: 4px;
              outline: none;
              color:$basic-text-gray;
              font-size: 14px;
              display: flex;
              align-items: center;
              padding-left: 17px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              transition: all 0.5s;
              cursor: pointer;

              &:hover{
                transition: all 0.5s;
                background: #ececec;
              }
              &:nth-child(n+2){
                border-top: none;
              }
            }
          }
        }


    }
    .head-options{
      width: 100%;
      height: 40px;
      background:$blue;
      display: flex;
      justify-content: flex-start;

      >div{
        box-sizing: border-box;
        line-height: 40px;
        color: $basic-gray;
        font-size: 14px;
        >a.name_sort,a.price-href{
        	color: #fff;
        	position:relative;
        	}
        >a[title]:hover:after{

  /*  content: attr(title);
    height: 26px;
    line-height: 26px;
    background: gray;
    border:1px solid black;
    padding: 0px 3px;
    position: absolute;
    left: 0;
    top: 120%;
    z-index: 200;
    white-space: nowrap;*/

        }

      }


    }
    .name{
      width: 200px;
      padding-left: 10px;
    }

    .options{
      width: 250px;
    }

    .price{
      width: 135px;
    }

    .amount{
      width: 80px;
    }
    .towar{
      width: 100%;
      min-height: 100px;
      background: transparent;
      box-sizing: border-box;
      border: 1px solid #e0e0e0;
      border-top: none;
      display: flex;
      justify-content: flex-start;

      >div{
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        color: #000;
        box-sizing: border-box;
        padding: 10px 0;


      }
      .name {
        padding-left: 20px;
        position:relative;
        >img{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			    width: 93%;
			height: 100%;
			z-index: -1;
			opacity: 0.2;
        }

        >div , a{
          margin-top:5px;
        }
        a.tovar-name {
          font-size: 14px;
          color: #000;
          text-transform: uppercase;
          text-decoration: none;
          font-weight: bold;
          padding-right: 14px;
          &:hover{
            text-decoration: underline;
          }
        }
        .star_rating{
          width: 70px;
          height: 13px;
          display: flex;
          justify-content: space-between;

          i{
            font-size: 13px;
            color:#000;
          }
        }
        .artukul{
          font-size: 10px;
          color:#000;
          span{
            color:#000;
          }
        }
        a.bin{
          color:#000;
          font-size: 27px;
        }
      }

      .options{
        .preizvoditel{
          border: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          min-height: 50px;
          justify-content: center;
          line-height: 20px;


          a{
            color: #000000;
          }
        }



        >div{
          font-size: 14px;
          width: 90%;
          min-height: 30px;
          border-top: 1px solid $basic-gray;
          color: $basic-text-gray;
          display: flex;
          align-items: center;



          >div{
            display: flex;
            align-items: center;
            width: 35%;
            height: 100%;

            &:last-child{
              width:65%;
              color: #000;
              display: block;

            }
          }

          .texst-description{
            line-height: 16px;
            font-size: 13px;
          }

          .number-value{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 30px;
          }
        }
      }
      .price{
        display: flex;
        justify-content: center;
        padding-left: 10px;
        .price-old{
          span{
            font-weight: bold;
            font-size: 15px;
            color: #0e0e0e;
            text-decoration: line-through;
          }

        }
        .price-current{
          color:$basic-green;
          font-size: 25px;
        }
      }
      .amount{
        .amount-grup{
          width: 50px;
          height: 38px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          font-size: 17px;
          border: 1px solid #e1e1e1;
        }
      }

      .adToBin{
        min-height: 100%;
        width: 57px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        >div{
          color: #fff;
          text-decoration: none;
          font-size: 25px;
          padding: 6px;
          background: $blue;
          cursor: pointer;
          &:hover{
            background:lighten($blue,5%);
          }
          &.chekedtovar{
            background: $basic-green;
            border-radius: 50%;
          }
        }
      }

    }
  }



  .kategory_pikazka{
    padding: 10px;
    font-size: 16px;
    color: #000;
  }

}


.curentPositionAndCategory {
  width: 100%;
  min-height: 65px;

  .hlebni_krozki {
    font-size: 14px;
    color: black;
    width: 100%;
    span.rozdelitel {
      width: 20px;
      display: inline-block;
      text-align: center;
    }

  }
  .curent_category {
     font-size: 18px;
    color: #000;
    width: 100%;
    margin-top: 5px;
  }
}


#searchErrorForm{
	position:relative;
background: transparent;
float:left;
        height: 456px;
    width: 275px;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
	  >div{
			    height: 23px;
			width: 239px;
			 position:relative;
			 .error{
				top: 0px !important;
				left: initial !important;
				right: -207px;
				>div{
					position: absolute;
					border: 10px solid transparent;
					border-right-color: #ec4848;
					bottom: 1px;
					left: -17px;
				}
			 }
	  }
	.zakazatButton{
		width: 150px !important;
		font-size: 17px !important;
		margin-top: 10px !important;
		height:30px !important;
		line-height: 30px !important;
		background: $basic-green;
		    color: #fff;
    text-align: center;
    cursor: pointer;
	}
	.close_file{
		display:none;
		position:absolute;
		cursor:pointer;
		color:red;
		       right: 6px;
	}
	#close_file_1{
		    bottom: 141px;
	}
	#close_file_2{
		bottom: 103px;
	}
	#close_file_3{
		    bottom: 67px;
	}
	.textarea{
		     height: 100px;
		     position:relative;

		        width: 100%;
		        textarea{
		        	border: 1px solid #70d4a0;
		        	    padding-left: 10px;
		        }
		         textarea:disabled{
		         	cursor:not-allowed;
		         	background:#f8f8f8;
		         }
	}
	#star_info{
    font-size: 12px;
    margin-top: -6px;
		align-self: flex-start;
		b{
			color:#ec4848;
		}
	}


		>div{
				 width: inherit;

			}
			div.important_input::after{
				left: 84px;

			}
			div.numberStar::after{
					left: 90px;
				}
			input[type=text],input[type=email],select{
				    border: 1px solid #70d4a0;
   				 padding: 3px;
   				     padding-left: 10px;
			}
			select{
				padding:0;
				height: 23px;
			}
			input:disabled,select:disabled{
				cursor:not-allowed;
				background:#f8f8f8;
			}
			.important_input::after,.textarea::after {
    position: absolute;
    content: '*';
    left: 3px !important;
    top: -1px !important;
    font-size: 16px;
    color: #ec4848;
}
		.jfilestyle.jfilestyle-corner{
    display: flex;
    margin: 0;
    flex-direction: row;
    align-items: flex-end;
    width: 247px;
   	margin-left: -30px;
			>input{
			    width: calc(100% - 50px) !important;
			    height: 25px !important;
			    box-sizing: border-box;
			    padding: 0;
			    padding-left: 10px;
			    line-height: 25px;
			    text-align: left;
			    border: 1px solid #c0c0c0;
			}
			    label{
					box-sizing: border-box;
					width: 50px !important;
					height: 25px !important;
					padding: 0  !important;
					line-height: 25px;
					text-align: center;
    				}
		}


}

.policy{
	    width: 100%;
    margin: 10px;
    font-size: 16px;
    color: #000;
    float: left;
        margin-top: -43px;
        text-align: justify;
    h2{
    	text-align:center;
    }
}
