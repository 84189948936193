.search_wrapper{
  width: 960px;
  //margin-top: 30px;
  border:1px solid darken($basic-gray,10%);
  border-radius: 5px;
  min-height: 400px;
  margin:30px auto;

.curent_category{
	margin-top:10px !important;
}

}

  .price-arrow{
    text-decoration: none;
    color: #eeeeee;
    width: 12px;
    display: inline-block;
    text-align: center;
    &:hover{
    	color: #70d4a0;
    }
    &.active{
    	color: #70d4a0;
    }
  }

  .jfilestyle.jfilestyle-corner{
  	    display: flex;
    flex-direction: row;
    >input{
    	width: calc(100% - 108px) !important;
    	height: 16px !important;

    }
    label{
    	    width: 50px !important;
    }
    span.focus-jfilestyle{

    }
  }

  #search_separator{
  	width:100%;
  	height:2px;
  	background:#70d4a0;
    margin-bottom: 15px;
  }

#searchInfoBlock{
	float:right;
	    height: 449px;
	    width: 430px;
	//background:red;
	display:flex;
	flex-direction:column;
	justify-content: space-between;


	.searchInfoBlock__h2{
	    font-size: 24px;
	    font-weight: bold;
	    text-align: center;
    	.searchInfoBlock__h2_b{
	    	color:#70d4a0;
	    }
	}
	.searchInfoBlock_p{
	    font-size: 14px;
	    color: black;
	    margin: 8px 0;
	    text-align:justify;

	    &.searchInfoBlock__h2-marginTop{
	    	    margin-top: 10px;
	    }
	    &.searchInfoBlock_fontsize{
	    	font-size:17px;
	    }
	}
	.searchInfoBlock__mainInfo{
		display:flex;
		    margin-top: 10px;
		        margin-bottom: 40px;
		.searchInfoBlock__mainInfo__list{
			width:350px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.searchInfoBlock__mainInfo__list__item{
				font-size:13px;
				display:flex;
				    align-items: center;
				.okIkon{
				    color: #70d4a0;
				    width: 21px;
				    height: 21px;
				    text-align: center;
				    line-height: 21px;
				    border-radius: 50%;
				    border: 1px solid #70d4a0;
				     margin-right: 6px;
				}
			}
		}
		.searchInfoBlock__mainInfo__list__img{
			img{
				    width: 115px;
			}
		}
	}
	.searchInfoBlock_p-color{
		color:#000;
	}
	.searchInfoBlock__footer{
		display:flex;
		flex-direction:column;
		align-items:center;
		    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60px;
    justify-content: space-between;
    margin-top: 9px;
		.foneNumber{
			>a{
				font-size: 15px;
				color: #435d96;
				font-weight: bold;
			}

		}
		>p{
			margin:0;
		}
	}
}
