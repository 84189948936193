.kwesionAnser{
    width: 100%;
    background: transparent;
    padding: 10px;
    font-size: 15px;
    margin: 30px 0;
    border: 2px solid #70d4a0;
    border-radius: 5px;
    box-sizing: border-box;
    background-position: center center;
    background-size: cover;
    position: relative;
	    &:after  {  
		    content: "";
		    width: 100%;
		    height: 100%;
		    background-image: url(/img/backgroundImage.png);
		    position: absolute;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    z-index: -1;
		    background-position: center center;
		    background-size: cover;
		    opacity: 0.2;
		}
  div{
    //margin: 5px 0;
  }
  img{
  	cursor:pointer;
  }


  .Anser div{
    margin-left: 3px;
    padding-left: 6px;
    border-left: 3px solid $blue;


  }

      .anser_foto{
    	height:50px;
    	border-left:none !important;
    	img{
    		height:100%;
    		margin: 0 5px;
    	}
    }

  .kwesion{
    margin-bottom: 10px;
    >div{
    	    display: flex;
    align-items: center;
    margin-top: 0;
	    >a:hover{
	    	font-size:17px;
	    }
    }
    .kwestion_info{
    	background: #70d4a0;
    	color: #fff;
    	 padding: 3px;
    	     margin-right: 8px;
    }
    .kwestion_footer{
    	margin-top:5px;
    }

    strong{
      margin-right: 4px;
    }
    .kwestion__foto{
    	    height: 26px;
    	img{
			height: 100%;
			margin: 0 5px;
			cursor: pointer
    	}
    }
  }
}


#userKwesthion{
	width: 100%;
    height: 333px;
	font-size: 16px;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
	padding-bottom: 8px;
	border-radius: 5px;
	    width: 50%;


  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    #name_kwetion_err{
      left: 0;
    }
  #text_kwetion_err{
    left: 33%;
  }
  }
  textarea {
    width: 300px;
    height: 88px;
    padding: 8px;
    padding-left:10px;
  }
  .reqwiredFiled{
    position:relative;
    &:after{
		position: absolute;
		content: '*';
		left: 3px !important;
		top: -1px !important;
		font-size: 16px;
		color: #ec4848;
    }
  }
  input{
    width: 200px;
    height: 20px;
       padding-left: 10px;


  }
#star_info{
    font-size: 12px;
    margin-top: 3px;
    align-self: flex-start;
    color: black;
    b{
    	color:red;
    }
}
  .file_wrapper{
  	    position: relative;
  	  input[type=file]{
		font-size: 11px;
		color: black;
 	 }
 	 >span{
 	 	display:none;
		position: absolute;
		color: red;
		cursor: pointer;
		right: 0;	 	
 	 }

  }


  .error{
    top: 0px !important;
    left: initial !important;
    right: -207px;
    >div{
		position: absolute;
		border: 10px solid transparent;
		border-right-color: #ec4848;
		bottom: 1px;
		left: -17px;
    }
  }


  .button{
width: 155px;
    font-size: 17px;
    height: 30px;
    background: #70d4a0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover{
      background: #435d96;
    }
  }

}


.kwestion_wrapper{
	display:flex;
	position:relative;
	
}


.kwestionInfo{
	width:50%;

	div{
    justify-content: center;
    align-items: flex-start;
    display: flex;
    color: #364b79;
    font-size: 20px;
    margin-top: 105px;
    background: rgba(255,255,255,0.7);
        text-align: center;
    font-weight: bold;
	}
}

#kwestionBackgroung{
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  top: -59px;
  width:100%;
  height: 114%;
  z-index:-1;
  opacity: 0.05;
}

