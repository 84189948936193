.zapros{

	.zapros_text{
		font-size: 20px;
		color: block;
		 margin-bottom: 50px;
		 h1{
			text-align: center;
			margin-top: 0;
			    color: #32456f;
			        font-size: 33px;
		 }
		 .imgWrapper{
			display: flex;
			justify-content: center;
			    .img{
				    color: #32456f;
				    font-size: 17px;
				    display: flex;
				    flex-direction: column;
				    justify-content: space-between;
				    align-items: center;
				        height: 69px;
				    img{
				    	    height: 35px;
				    }
			    }
		 }
		 .separator{
		 	        margin: 0px 25px;
		 	    i.fa{
					font-size: 23px;
					color: #32456f;
					line-height: 33px;
		 	    }
		 }

	}
	.curentPositionAndCategory{
		    height: 30px;
	}

	.flex-grid{
			display: flex;
			justify-content: space-around;
		.flex-item{
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	.tabButton{
		font-size: 17px;
		color: white;
		background: #05a705;
		padding: 6px;
		cursor: pointer;
		    width: 126px;
    text-align: center;
    &.disabled{
    	    color: #e2dfdf;
    background: #9c9c9c;
    }
    &:hover{
    			color: white !important;
		background: #05a705 !important;
	}
}
	.formGrup{
		position:relative;
		width: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;
		    margin-top: 10px;
		justify-content: space-around;

			.inputGrup{
				position: relative;
				.disabled{
					background: #ececec;
					cursor: not-allowed !important;
				}
				&.select{
					    width: 100%;
					    display:flex;
					input{
						width: 250px;
					}
					.select-header{
						width: 250px;
						margin: 4px 0;
						box-sizing: border-box;
						border: 1px solid #70d4a0;
						height: 25px;
						padding-left: 15px;
						position: relative;
						color: #000;
						    font-size: 13px;
						   cursor: pointer;
						line-height: 23px;
						&.plasehlder{
							    color: #757575;
							    font-family: Arial;
						}
					}
					.dropdoun_ikon{
						width: 50px;
						height: 25px;
						border: 1px solid #70d4a0;
						margin: 4px 0;
						box-sizing: border-box;
						font-size: 20px;
						text-align: center;
						cursor: pointer;
						background: #70d4a0;
						color: white;
					}

					 
					}   
				&.inportantFiled{

					&:after{
						position: absolute;
						content: '*';
						left: 5px !important;
						top: 6px !important;
						font-size: 16px;
						color: #ec4848;
					} 
					.error{
						display: flex;
						position: absolute;
						background: #ec4848;
						width: 132px;
						top: 4px;
						    right: -142px;
						justify-content: center;
						align-items: center;
						color: #fff;
						height: 25px;
						font-size: 13px;
						>div{
							position: absolute;
							border: 10px solid transparent;
							/* border-top-color: #ec4848; */
							border-right-color: #ec4848;
							bottom: 1px;
							left: -19px;
						}
					}
				}
				&.margin-bottom{
					margin-bottom:10px;
				}
			}


		   

		input,select{
		    width: 300px;
		    margin: 4px 0;
		    box-sizing: border-box;
		    border: 1px solid #70d4a0;
		    height: 25px;
		    padding-left: 15px;
		    position: relative;
			}
			select{
				padding-left: 11px;
			}
			textarea{
			    width: 300px;
			    margin: 4px 0;
			    box-sizing: border-box;
			    border: 1px solid #70d4a0;
			    height: 100px;
			    padding-left: 15px;
			    position: relative;
    font-size: 14px;
			}
		 textarea::-webkit-input-placeholder { color: $basic-text-gray }
        textarea:-moz-placeholder { color: $basic-text-gray; }
        textarea::-moz-placeholder { color: $basic-text-gray; }
        textarea:-ms-input-placeholder { color: $basic-text-gray; }
			.selectPlaseholder{
				display: none;
			}

	}
	.fotoComponent{
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin: 7px 0;
    width: 300px;
        position: relative;
		input{
			font-size: 11px;
			margin: 3px 0;
		}
		span{
			    font-size: 13px;
    color: #435d96;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;

    	&.disabled{
    		color: #808080 !important;
    		    cursor: not-allowed;
    	}
		}
		.zaslonka{
			    position: absolute;
    width: 89px;
    height: 18px;
    top: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    /* height: 100%; */
    cursor: not-allowed;
		}
	}
		.submitButton{
    font-size: 17px;
    width: 100%;
    height: 35px;
    text-align: center;
    background: #698ede;
    color: #fff;
    line-height: 30px;
    margin-top: 20px;
    cursor: pointer;
    border: 2px solid  #698ede;
    box-sizing: border-box;
		    &:hover{
		    	 box-sizing: border-box;
		    	 border:2px solid #698ede;
		    	 color: #698ede;
		    	 background: #fff;
			}
			&.disabled{
				user-select: none;
				background: #ababab;
				border: none;
				line-height: 32px;
				cursor: not-allowed;
				  &:hover{
					user-select: none;
					background: #ababab;
					border: none;
					line-height: 32px;
					cursor: not-allowed;
					color:#fff !important;
				  }
			}
	}
}

				.inputGrup__add{
					font-size: 13px;
					color: green;
					text-decoration: underline;
					cursor: pointer;
					font-weight: bold;
					&.disabled{
						cursor:not-allowed;
						color:gray;
					}
				}
				.ajax_component{
					margin-bottom: 5px;
					    position: relative;
				}


									.dropdoun_list{
						position: absolute;
						width: 300px;
						z-index: 11;
						font-size: 13px;
						background: #e4e2e2;
						 max-height: 300px;
						overflow-x: hidden;
						overflow-y: scroll;
						box-sizing: border-box;
						color: black;
						    left: 0;
						    top: 30px;
						.dropdoun_list_item{
							padding: 5px;
							cursor: pointer;
							border-bottom: 1px solid #9e9494;
							&:hover{
								background:#f5f5f5;

							}
						}
					}


.deleteService{
	    position: absolute;
    width: 20px;
    height: 25px;
    right: -29px;
    top: 3px;
    color: red;
    line-height: 30px;
    font-size: 17px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.zaslonka{
	user-select: none;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 125%;
    top: 0;
        background: rgba(138, 138, 138, 0.49);
}

.zaslonka_right{
	    user-select: none;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 130%;
    top: 0;
        background: rgba(138, 138, 138, 0.49);
}

