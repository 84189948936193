@import '_reset.scss';
@import "_variables.scss";



@import "_header.scss";
@import "_asideMenu.scss";
@import "_slider.scss";
@import "_recomend.scss";
@import "_main.scss";
@import "_news.scss";
@import "pagination";

@import "_search.scss";

@import "footer";
@import "tovarItem";
@import "bin";
@import "anserReply";

@import "about";
@import "zapros";


.kategory_text_pikazka{
  width: 100%;
  font-size: 15px;
  color: #000;
}

@import '_paginationFrame.scss';
