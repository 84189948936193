

.header-top{
  width: 100%;
  height: 55px;
  background: #435d96;

  .header-top-insade{
    display: flex;
    justify-content: space-between;
    width: 960px;
    height: 100%;
    margin:0 auto;
    align-items: center;
    a.logo{
      height: 40px;
       transition: 0.3s;
       position: relative;
       left: -3px;
       padding: 1px;
       padding-left: 2px;
       border:1px solid transparent;
      img{
         height: 100%;
      }
        &:hover{
          
          
    border: 1px solid #70d4a0;
          
          transition: 0.3s;
          
        }
    }
    
    h1{
      a{
        text-decoration: none;
        color: #fff;
        font-size: 36px;
        margin:0;
        transition: 1s;

      }
    }

    .fone-number{
      color: #fff;
      font-size: 20px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 200px;
      text-decoration: none;

      .fa-phone{
        font-size: 25px;
        color: $basic-green;
      }
    }


    .korzina{
      color:  $basic-green;
      font-size: 17px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
          width: 155px;
      cursor: pointer;
      text-decoration: none;

      .fa-shopping-basket{
        font-size: 25px;
        color: $basic-green;
      }
      span{
        color: #fff;
      }
    }

    .social_icons{
      width: 106px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      a{

        i{
          font-size: 17px;
          color: #5d75ad;

            &:hover{
              color: $basic-green;
            }
        }
      }
    }
  }
}




.header-bottom{
    height: 35px;
    width: 100%;
    background: #70d4a0;

  .header-bottom-inside{
    width: 960px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin:0 auto;

    .search{
      
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 25px;
        width: 470px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: relative;
        transition: 1s;
        border: 2px solid #fff;
        padding: 0 8px;
      &.search-active{
        border: 2px solid #fff;
        box-sizing: content-box;
        transition: 1s;
        padding: 0 8px;
        background: #fff;
            input::-webkit-input-placeholder { color: #435d96; }
            input:-moz-placeholder { color: #435d96; }
            input::-moz-placeholder { color: #435d96; }
            input:-ms-input-placeholder { color: #435d96; }
            .fa-search{
              color: #435d96;
            }
      }

      input{
    height: 100%;
    border: none;
    background: transparent;
    outline: none;
    color: #443d3d;
    padding-top: 1px;
    width: 433px;
    font-size: 12px;
      }

      input::-webkit-input-placeholder { color: #fff; }
input:-moz-placeholder { color: #fff; }
input::-moz-placeholder { color: #fff; }
input:-ms-input-placeholder { color: #fff; }

      .fa-search{
    color: white;
    font-size: 20px;
    cursor: pointer
      }
      #gif{
        height: 90%;
        display: none;
      }
      .pidkazki{
    position: absolute;
    width: 100%;
    z-index: 10000;
    left: 0;
    top: 35px;
    background: #70d4a0;
    border-bottom: 2px solid #eee;
    box-sizing: border-box;
    display: none;
    max-height: 305px;
    /* overflow: scroll; */
    overflow-y: scroll;
        >a{
          display: flex;
          text-decoration: none;
          font-size: 12px;
          justify-content: flex-start;
          padding-left: 10px;
          align-items: center;
          height: 30px;
          width: 100%;
          border: 2px solid $basic-gray;
          border-bottom: none;
          box-sizing: border-box;
          color: #000;
          cursor: pointer;
          &:hover{
            background: darken($basic-green,10%);
          }
          strong{
            margin-right: 5px;
           // color:#000;
          }
          &.notFullMatchLink{
            background: #b8c7bf;
            &:hover{
              background: darken($basic-green,10%);
            }
          }
        }

      }
    }

    .menu{
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 460px;

      a{
        font-size: 19px;
        color: #fff;
        height: 100%;
        line-height: 35px;
        cursor: pointer;
        display: block;
        text-decoration: none;
        i{
          display: none;

        }

        &:hover{
          background: darken($basic-green,10%);
        }
      }
    }
  }

  &.onscroll{
    position: fixed;
    top: 0;
    z-index: 20;
    left: 0;
    height: 40px;

    .menu{
      flex-direction: COLUMN;
      width: 200px;
      align-items: FLEX-START;
      justify-content: flex-end;
      background: $basic-green;
      position: absolute;
      left: 30px;
      top: 0;
      height: auto;
      z-index: 20;
      transition: height 2s;
      .opition{

        height: 40px;
        line-height: 40px;
        order: 0;
        
        &:hover{
          background: $basic-green;
        }
        i{
          display: inline;
          margin-left: 5px;

        }
      }
      >a{
        //height: 40px;
        line-height: 40px;
        padding-left: 5px;
        width: 100%;
        box-sizing: border-box;
        height: 0;
        overflow: hidden;
        order: 1;

      }
      &.visible{
        a{
          height: 40px;

        }
      }
    }
    .header-bottom-inside{
      justify-content: flex-end;
      .search{
        height: 28px;
       // width: 400px;
        i{
          font-size: 23px;
        }
        input{
          width: 370px;
        }
      }
      .pidkazki{
        //width: 400px;
        top:40px;

        }
    }
  }
}

.transition-height-js{
  transition: height 0.5s;
}

    .header_zapros{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 205px;
    height: 30px;
    background: #eee;
    text-decoration: none;
    color: #435d96;
    font-weight: bold;
    padding: 0 10px;
    border: 1px solid #70d4a0;
      img{
        height: 90%;
      }
      span{
            font-size: 19px;
      }
    }