.pagination{
  height: 40px;
  display: flex;
  margin: 35px auto;
  width: auto;
  justify-content: center;
  clear: both;

  >a,div{
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 10px;
    background: $basic-green;
    color: #fff;
    font-size: 19px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;

    &:hover{
      background:$red;
    }
    &.pagination-curent{
      background: $red;
    }

  }
  .pagination-previos,.pagination-nekst{
    border-radius: 3px;
    background: $basic-green;
    i{
      line-height: 40px;
    }
    &:hover{
      background:lighten($basic-green,10%);
    }
    //&.disable{
    //  background: $basic-gray;
    //  cursor: ;
    //}
  }
  .pagination-coming{
    line-height: 45px;
    cursor: default;
    background: darken($basic-gray,10%);
    font-size: 28px;
    border-radius: 3px;

    &:hover{
      background: darken($basic-gray,10%);
    }
  }
}