.tovar_wrapper{
  width: 960px;
  margin: 30px auto 0 auto;
  min-height: calc(100% - 250px);
      position: relative;
// .tovar-mainItem-action-utochnit
  aside{
    float: left;
    margin-right: 40px;

    .menu-wrapper{
      background: #fff;
    }
  }

  .curentPositionAndCategory{

    .curent_tovar{
      >h1{
    font-size: 21px;
    color: #000;
    width: 100%;
    margin-top: 19px;
      }

    }
  }

  .tovar_wrapper-mainItem{
    margin-top: 30px;
    display: flex;
    width: 700px;
.tovarimgs{
    width: 285px;

  .curentImgWrap{
    position: relative;
    max-height: 100%;
    width: 45px;
    height: 45px;
    margin: 5px 3px 0 3px;
    float: left;
    img{
      width: 100%;
      height: 100%;
      cursor: pointer;
      &[src="../img/noFoto.jpg"]{
        cursor: default;
      }
    }
    &:first-child {
    max-height: 100%;
    width: 90%;
    height: 216px;
    margin: 0;
    }


    &:first-child .zoom{
      position: absolute;
      width: 30px;
      height: 30px;
      z-index: 11;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.57);
      color: #fff;
      font-size: 23px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }

    .zoom{
    position: absolute;
    width: 15px;
    height: 15px;
    z-index: 11;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.21);
    color: #fff;
    font-size: 11px;
    text-align: center;
    line-height: 15px;
    cursor: pointer;
    }
  }
}


    .tovar-mainItem-infowrap{
      width: 380px;
      height: 100%;

      .tovar-mainItem-priceAndRatimg{
        min-width: 100px;
        height: 27px;
        >div{
		    float: left;
		    padding: 0 5px;
		    box-sizing: border-box;
		    font-size: 30px;
		    margin-top: 7px;
		    text-align: center;
		    >span.tovar-mainItem-dostavka{
		    	font-size:16px;
		    	text-decoration:underline;
		    	margin-left: 20px;
		    }
        }
        .tovar-mainItem-price-old{
          color: $dark-red;
          text-decoration: line-through;
        }


      }
      .star_rating{
		    float: left;
		    padding: 0 5px;
		    height: 33px;
		    box-sizing: border-box;
		    font-size: 25px;
		    color: #70d4a0;
		    line-height: 35px;
		    text-align: left;
		    width: 100%;
		       margin-bottom: 10px
      }

      .tovar-mainItem-action{
    width: 365px;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
        >div,>a{
          height: 35px;
          font-size: 13px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          border-radius: 3px;
          text-decoration: none;
        }
        .tovar-mainItem-action-amount{
        width: 45px;
        background: #fff;
        border: 1px solid #d5d4d4;
        }
        .tovar-mainItem-action-addToBin{
          width: 131px;
          background: #70d4a0;
          color: #fff;
          cursor: pointer;
          &:hover{
            background: lighten($basic-green,10%);
          }
        }
        .tovar-mainItem-action-buy,.tovar-mainItem-action-utochnit{
            background: #4080ff;
            user-select: none;
            color: #fff;
            padding: 0 9px;
            cursor: pointer;
          &:hover{
            background: #6394f7;
          }
        }
      }

      .tovar-mainItem-shortInfo{
    width: 95%;
    height: 96px;
    background: #fff;
        >div{
          width: 100%;
          height: 33.3333333333%;
          display: flex;

          >div{
		    width: 50%;
		    height: 100%;
		    font-size: 14px;
		    box-sizing: border-box;
		    padding: 10px;
		    line-height: 14px;

          }
        }
      }
    }
  }

}




  .FullInfo{
      width: 100%;
  //height: 500px;
  margin:20px auto 0 auto;

    .FullInfo-menu{
      height: 40px;
      width: 420px;

      display: flex;
      justify-content: space-between;
      >div{
     width: 100px;
    height: 100%;
    background: #70d4a0;
    color: #fff;
    font-size: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    height: 35px;
        &:hover{
          background: lighten($basic-green,5%);
        }
      }
      .FullInfo-menu-active{
    color: #000;
    border-top: 4px solid #435d96;
    background: #fff;
    height: 100%;
    /* line-height: 22px; */
    box-sizing: border-box;
    padding-bottom: 8px;
        &:hover{
          background: #fff;
        }
      }
    }

    .FullInfo-menu-main{
      background: #fff;

      #FullInfo-menu-main-PropertyValue{
        width: 100%;
        display: none;

        >table{
          width: 95%;
          margin:0 auto;
          padding-top: 20px;
          padding-bottom: 20px;
          tr{
            height: 50px;

            td{
              border-bottom: 1px solid darken($basic-gray,5%);
              width: 100%;
              font-size: 16px;
              text-align: left;
              padding-left: 10px;
            }
            &:last-child{
              th,td{
                border: none;
              }
            }
          }
        }
        &.FullInfo-menu-main-active{
          display: block;
        }
      }



      #FullInfo-menu-main-description,#FullInfo-menu-main-dostavka,#FullInfo-menu-main-comments{
        font-size: 15px;
        box-sizing: border-box;
        padding: 20px;
        color: #000;
        display: none;
        position:relative;
        text-align:justify;
        .hiden-dostavka-info{
	  	  margin-top: 5px;
	  	  display:none;
       	 .FullInfo-menu-main-dostavka-infoblock{
       	 	text-align:justify;
			border-top: 1px solid #70d4a0;
		    margin-bottom: 5px;
        	    .waring{
        	    	color:red;
        	    }
      	  }
      	  .dostavka-h3{
      	  	font-size:17px;
      	  	color:#70d4a0;
      	  	font-weight:bold;
      	  }
        }

        &.FullInfo-menu-main-active{
          display: block !important;
        }

          .getMoreComents{
            position:relative;
			cursor: pointer;
			text-decoration: underline;
			margin: 15px 0;
			font-size: 21px;
        }
        #userComent{
          width: 100%;
          height: 255px;
          display: flex;
          flex-direction:column;
              justify-content: space-between;
          >.column{
            width: 70%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 13px 0;
            position: relative;


            &:first-child{
              width: 30%;
            }
          }



          #usr_star_rating{
            cursor: pointer;
            position: relative;
            position: relative;
            i{
				display: block;
				float: left;
				width: 30px;
				height: 30px;
				padding: 0 2px;
				font-size: 31px;
				color: $basic-green;

            }
              #tovar_coment_rating_err{
    left: -8px;
    top: -32px;
            }
          }
          .coment-textarea{
          	position:relative;
				textarea{
					width: 95%;
					height: 100px;
					padding: 7px;
				}
				#tovar_coment_text_err{
    left: 400px;
    top: -28px;
				}
          }


          .name{
            width: 200px;
            position: relative;
            
            input{
              width:100%;
              height: 20px;
                  padding: 3px;
            }

            #tovar_coment_name_err{
				left: 170px;
				top: -28px;
            }
          }

          .button{
    width: 180px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #70d4a0;
    color: #fff;
    cursor: pointer;

            &:hover{
              background:lighten($basic-green,5%);
            }
          }
        }
        .comment{
        	display:none;
          border:1px solid darken($basic-gray,5%);
          padding: 10px;
          margin:10px auto;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 5px;
          .nameKoment{
			margin-bottom: 10px;
			font-size: 20px;
			font-weight: bold;
			display: flex;
			height: 100%;

          }
          .stars {
               color: #70d4a0;
    margin-left: 10px;
    margin-right: 10px;
          }
          .main{

          }
        }
      }
    }

}



#fastByu{
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -300px;
    margin-top: -180px;
    z-index: 1000;
    border: 3px solid #70d4a0;
    background-image: url(/img/oneclick.png);
    overflow: hidden;
    display: none;
      .urInfo{
      	bottom: 50px;
      }
    >#otmena{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 31px;
      color: #ec4848;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 9px;
      top: -1px;
      z-index: 10000000;
      cursor: pointer;
    }
    >.buyStaticForm {
      font-size: 20px;
      height: 275px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 600px;
      flex-wrap: wrap;
      border-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      align-content: center;
      background:transparent;

        .textarea {
          height: 155px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
           textarea{
            width: 245px;
                height:107px;

            border: 2px solid #70d4a0;
           }
           .fastByu_amount_wrap{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 252px;
					.amount-grup{
					    width: 105px;
   						 height: 29px;
   						 >div{
				 	    	height: 29px;
    						width: 30px;
   						 }
   						 >.showMaxVal{
							width: 128px;
							font-size: 14px;
							top: -39px;
							height: 26px;
							>div{
								    right: 5px;
							}
   						 }
					}
           }
        }
      input {
        width: 200px;
        height: 25px;
        padding-left: 5px;
        border: 2px solid #70d4a0;
      }
      .buyStaticForm-h1{
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 12px;
      }
  }
  .buyStaticForm .zakazat {
    width: 200px;
    text-align: center;
    height: 35px;
    line-height: 35px;
    background: #70d4a0;
    color: #fff;
    cursor: pointer;
    box-sizing: border-box;
    margin-top: 30px;
        border-radius: 0;
    &:hover{
      background:#435d96;
    }
}
}



.analogi{
  margin:20px auto;
  width:100%;
  .recomend{
    width:100%;
  }
  .pagination{
  padding-top: 20px;
}
}

.error{
  display: none;
  position: absolute;
  background: #ec4848;
width: 200px;
  top: -5px;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 25px;
  font-size: 16px;
  >div{
    position: absolute;
    border:10px solid transparent;
    border-top-color: $red;
    bottom: -20px;
    left: 15px;
  }
}

.infoOk{
    position: fixed;
    flex-direction: column;
    justify-content: space-around;
    left: 50%;
    top: 50%;
    color: black !important;
    z-index: 1111111111;
    min-width: 370px;
    margin-left: -185px;
    height: 90px;
    margin-top: -40px;
    background: #f8f8f8;
    color: #fff;
    font-size: 20px;
    border: 2px solid #70d4a0;
    box-sizing: border-box;
    padding: 10px 0;
    display:none;
    >span{
    font-size: 13px;
    color: #6f6e6e;
    text-align: center
    }
    >div{
    display: flex;
    justify-content: center;
    font-size: 23px;
    align-items: center;
      >span{

      }
            >div{
                background: transparent;
                color: #fff;
                font-size: 23px;
                text-align: center;
                line-height: 22px;
                box-sizing: border-box;
                width: 20px;
                border-radius: 50%;
                height: 20px;
                margin-right: 10px;

        >i{
        color: #70d4a0;
      }
    }
    }

  
}

.bigImg{

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.87);
 // display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  img{
    cursor:pointer;
  }

  #close{
    position: absolute;
    width: 25px;
    height: 26px;
    background: transparent;
    z-index: 1111111111111111111;
    color: #ffffff;
    font-size: 19px;
    text-align: center;
    line-height: 22px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    cursor: pointer;
    right: 20px;
    top: 20px;
  }

  .otherImg{
    height: 12%;
    display: flex;
    /* margin-top: 10px; */
    position: absolute;
    bottom: 30px;
  	img{
height: 100%;
    width: 75px;
    cursor: pointer;
    border: 3px solid #fff;
    border-radius: 10px;
    margin: 5px;
    cursor:pointer !important;
  	}
  }

  .mainImg{
  	    display: flex;
    align-items: center;
    justify-content: center;
    height:97%;
    max-width: 90%;
    position:relative;

    img{
    	
    max-height: 100%;
    cursor: default;
    }


   .jsNavigation-item {
    	    width: 30px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    line-height: 97%;
    text-align: center;
    background: rgba(152, 147, 147, 0.43);
    font-size: 40px;
    margin-right: 10px;
    z-index: 1111;
    color: #fff;
    display: none;
    }

    #js-p-right{
    	margin-right:0;
    	margin-left:10px;
    }
  }
}



	.tovar-content-wrapper{
		width:700px;
		float:right;
	}

.recomend{
	.recomend-text-header{
		    width: 100%;
	    height: 30px;
	    background: gray;
	    color: #fff;
	    font-size: 17px;
	    text-align: center;
	    line-height: 30px;
	}

	.recomend-table-header{
		    width: 100%;
	    height: 30px;
	    background: #cccccc;
	    color: #fff;
	    font-size: 15px;
	    display: flex;

	    >div{
	    	    border: 1px solid #bbbbbb;
	    	    line-height: 30px;
	    text-align: center;
	        box-sizing: border-box;
	    }
	}


	.recomend-foto{
		width: 82px;
		    padding: 0 !important;
	}

	.recomend-name{
	    width: 180px;
	}
	.recomend-number{
		width:115px;
	}

	.recomend-price{
		width:60px;
	}

	.recomend-description{
		        width: 274px;
	}

	.recomend-item{
		width:100%;
	    height: 82px;
	    display:flex;
	        font-size: 16px;

	    >div{
			border: 1px solid #bbbbbb;
			padding: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing:border-box;
	    }

	    img{
	    	width: 79px;
    height: 80px;
	    }


	}

	    ul{
	    	margin:0 !important;
	    }

}



.buyInfo{
   // display: flex;
    position: fixed;
    z-index: 11111111111;
    width: 380px;
    height: 200px;
       	 background: url(/img/modal.png);
    background-size: cover;
    left: 50%;
    top: 50%;
    margin-left: -200px;
    margin-top: -100px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 2px solid #70d4a0;
    display:none;
    .buyInfo-button{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 31px;
      background: #70d4a0;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      &:hover{
        background:#435d96;
      }
    }
    .buyInfo-ok{
      display: flex;
      width: 70px;
      height: 70px;
      background: #70d4a0;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      border-radius: 50%;
      color: #fff;
    }
    span{
          font-size: 24px;
    }
    span.buyInfo-zakazId{
          font-size: 20px;
          text-align: center;
    }
    #zakazId{
          font-size: 20px;
    color: #444141;
    }
}


#moreInfo,#lessInfo{
	    color: rebeccapurple;
    text-decoration: underline;
    cursor: pointer;
}


.modal{
	 display: flex;
    position: fixed;
    z-index: 11111111111;
    width: 380px;
    height: 200px;
   	 background: url(/img/modal.png);
    background-size: cover;
    left: 50%;
    top: 50%;
    margin-left: -200px;
    margin-top: -100px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 2px solid #70d4a0;
    .modal-button{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 31px;
      background: #70d4a0;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      &:hover{
        background:#435d96;
      }
    }
    .modal-ok{
      display: flex;
      width: 70px;
      height: 70px;
      background: #70d4a0;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      border-radius: 50%;
      color: #fff;
    }
    span{
          font-size: 26px;
    }
    span.important{
          font-size: 20px;
    }
    #zakazId{
          font-size: 20px;
    color: #444141;
    }
}


.tovar_link{
	    display: block;
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
        margin-top: 0 !important;
        cursor:pointer;
    &:hover{
    	    background: rgba(202, 202, 202, 0.18);
    }
}

.zaprosDemo{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    float: left;
    border-radius: 3px;
    margin-top: 10px;
}

.zaprosDemo_header{
  font-size: 17px;
  height: 32px;
  background: #e4dfdf;
  font-weight: bold;
  color: #636363;
  padding-left: 20px;
  line-height: 32px;

}

.zaprosDemo_item{
      font-size: 15px;
      margin-bottom: 3px;
}

.zaprosDemo_footer{
float: right;
      .header_zapros{
            width: 243px;
            height: 32px;
      }
}

.zaprosDemo_item_body{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #F8F8F8;
    /* margin-bottom: 3px; */
    border: 1px dashed #e4dfdf;
    padding: 6px;
    height: 33px;
    line-height: 33px;
    .left{
          user-select: none;
    float: left;
    height: 100%;
    max-width: 448px;
    overflow: hidden;
    }
    .img{
      float: left;
      user-select: none;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
          img{
        max-height: 27px;
        max-width: 59px;
        float: left;
      }
    }

    div{
      float: left;
    }
    .zaprosDemo_item_body_region{
          user-select: none;
    float: left;
    width: 200px;
    text-align: center;
    }
    .zaprosDemo_item_body_time{
          display: flex;
    flex-direction: column;
    align-items: center;
    float: right;
        line-height: 14px;
    }
    .zaprosDemo_item_body_text{
          margin-right: 7px;
    }

}

.right{
  float: right !important;
}


#utochnitNalichie{
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    margin-top: -120px;
    z-index: 1000;
    border: 3px solid #70d4a0;
    background-image: url(/img/oneclick.png);
    overflow: hidden;
    display: none;
      .urInfo{
        bottom: 50px;
      }
    >#otmena_nalichie{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 31px;
      color: #ec4848;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 9px;
      top: -1px;
      z-index: 10000000;
      cursor: pointer;
    }
    >.buyStaticForm {
      font-size: 20px;
      height: 240px;
      flex-direction: column;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 300px;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      border-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      -ms-flex-line-pack: center;
      align-content: center;
      background: transparent;
      .inputGrup{
            height: 88px;
    width: 290px;

      }

        .textarea {
    height: 80px;
    width: 286px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
           textarea{
            width: 245px;
                height:107px;

            border: 2px solid #70d4a0;
           }
           .fastByu_amount_wrap{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 252px;
          .amount-grup{
              width: 105px;
               height: 29px;
               >div{
                height: 29px;
                width: 30px;
               }
               >.showMaxVal{
              width: 128px;
              font-size: 14px;
              top: -39px;
              height: 26px;
              >div{
                    right: 5px;
              }
               }
          }
           }
        }
      input {
        width: 200px;
        height: 25px;
        padding-left: 5px;
        border: 2px solid #70d4a0;
      }
      .buyStaticForm-h1{
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 12px;
      }
  }
  .buyStaticForm .zakazat {
    width: 205px;
    text-align: center;
    height: 35px;
    line-height: 35px;
    background: #70d4a0;
    color: #fff;
    cursor: pointer;
    box-sizing: border-box;
    margin-top: 30px;
    border-radius: 0;
    &:hover{
      background:#435d96;
    }
 }
}

.fb-comments{
      background: white;
    margin-top: 15px;
}

