body{
  padding: 0;
  margin:0;
  outline: 0;
  font-size: 0;
  font-family: arial;

}
body,html{
  height: 100%;
}